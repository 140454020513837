if (document.querySelector('.projects-main__tab')) {
  const projectsTab = $('.projects-main__tab-link');
  const projectsDropdownBtn = $('.projects-main__dropdown-btn');
  const projectsDropdown = $('.projects-main__tabs');

  projectsTab.each(function () {
    $(this).on('click', function () {
      projectsTab.each(function () {
        $(this).removeClass('active');
      });
      $(this).addClass('active');
      if (window.screen.width <= 768) {
        projectsDropdownBtn.children('span').text($(this).text());
        projectsDropdownBtn.removeClass('active');
        projectsDropdownBtn.parent().removeClass('active');
        projectsDropdown.slideUp();
        $('body').css({ overflow: 'auto' });
      }
    });
  });

  if (window.screen.width <= 768) {
    projectsDropdownBtn.on('click', function () {
      $(this).toggleClass('active');
      $(this).parent().toggleClass('active');
      projectsDropdown.slideToggle();

      if ($(this).hasClass('active')) $('body').css({ overflow: 'hidden' });
      else $('body').css({ overflow: 'auto' });
    });
  }

  const swiperProjectsMain = new Swiper('.projects-main__slider', {
    speed: 800,
    loop: true,
    mousewheel: {
      sensitivity: 5,
    },
    breakpoints: {
      769: {
        slidesPerView: 8,
      },
      100: {
        slidesPerView: 5,
      },
    },
  });

  $('.projects-main__card').each(function () {
    $(this).on('click', function () {
      if ($('.projects-main__content').hasClass('hidden')) $('.projects-main__content').removeClass('hidden');

      if ($(this).parent().next().children(this).hasClass('active')) swiperProjectsMain.slidePrev();
      else if ($(this).parent().prev().children(this).hasClass('active')) swiperProjectsMain.slideNext();

      $('.projects-main__content-box, .projects-main__card').each(function () {
        $(this).removeClass('active hover');
      });
      $(this).addClass('active hover');
      $(`#${$(this).data('project')}`).addClass('active');
    });
  });
}
