if ($('.smartmoney-investment').length !== 0) {
  let dropdownBtn = $('.smartmoney-investment__tab-box.active')
    .children('.smartmoney-investment__dropdown-box')
    .children('.smartmoney-investment__dropdown-btn');
  const dropdownAll = $('.smartmoney-investment__dropdown--all');
  const tabContainer = $('.smartmoney-investment__tab-container');

  function dropdown() {
    $(this).toggleClass('active').next().slideToggle();

    if (dropdownBtn.not('.active').length === 0) dropdownAll.addClass('hidden');
    else dropdownAll.removeClass('hidden');

    if (window.screen.width < 768) {
      const dropdownEl = $(this).next();

      if (dropdownBtn.not('.active').length === 0) {
        $('.smartmoney-investment__dropdown').each(function () {
          $(this).removeClass('active');
        });
        dropdownAll.addClass('active');
      } else {
        if (!$(this).is(dropdownBtn.last()) && $(this).hasClass('active')) {
          $('.smartmoney-investment__dropdown').each(function () {
            $(this).removeClass('active');
          });

          dropdownAll.addClass('hidden');
          dropdownEl.addClass('active');

          setTimeout(function () {
            dropdownAll
              .css({ top: `${Math.round(dropdownEl.position().top + dropdownEl.outerHeight())}px` })
              .removeClass('hidden')
              .addClass('active');
          }, 500);
        } else if ($(this).not('.active')) {
          $('.smartmoney-investment__dropdown').each(function () {
            $(this).removeClass('active');
          });

          dropdownAll.removeClass('active').removeClass('hidden');
        }
      }
    } else {
      if (dropdownBtn.last().hasClass('active')) dropdownAll.addClass('hidden');
      else dropdownAll.removeClass('hidden');
    }
  }

  if (window.screen.width < 768) tabContainer.css({ 'grid-row': '2' });

  $('.smartmoney-investment__tab').each(function (index) {
    $(this).on('click', function () {
      if (tabContainer.hasClass('hidden')) {
        tabContainer.removeClass('hidden');
        tabContainer.slideDown();
      }

      $('.smartmoney-investment__tab, .smartmoney-investment__tab-box').each(function () {
        $(this).removeClass('active');
      });
      $(this).addClass('active');
      $(`[data-tab-smartmoney='${$(this).attr('id')}']`).addClass('active');

      if (window.screen.width < 768) tabContainer.css({ 'grid-row': `${index + 2}` });

      dropdownBtn = $('.smartmoney-investment__tab-box.active')
        .children('.smartmoney-investment__dropdown-box')
        .children('.smartmoney-investment__dropdown-btn');

      dropdownBtn.each(function () {
        $(this).removeClass('active').next().slideUp();
        $(this).off('click', dropdown);
        $(this).on('click', dropdown);
      });
      dropdownAll.removeClass('hidden').removeClass('active');
    });
  });

  dropdownBtn.each(function () {
    $(this).on('click', dropdown);
  });

  dropdownAll.on('click', function () {
    dropdownBtn.each(function () {
      $(this).addClass('active').next().slideDown();
    });
    $(this).addClass('hidden');
    if (window.screen.width < 768) {
      $(this).addClass('active');
      $('.smartmoney-investment__dropdown').each(function () {
        $(this).removeClass('active');
      });
    }
  });
}
