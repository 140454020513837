if (document.querySelector('.history__slider')) {
  const swiperMassHistory = new Swiper('.history__slider', {
    speed: 800,
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    navigation: {
      nextEl: '.history__slider-btn--next',
      prevEl: '.history__slider-btn--prev',
    },
    a11y: {
      prevSlideMessage: 'Назад',
      nextSlideMessage: 'Вперёд',
    },
  });

  const slidesHistory = $('.history__slide');

  if (window.screen.width <= 768) {
    slidesHistory.each(function (index) {
      $(this).on('click', function () {
        slidesHistory.each(function () {
          $(this).removeClass('active');
        });
        $(this).addClass('active');
        swiperMassHistory.slideTo(index);
      });
    });
    $('.history__slider-btn--prev').on('click', function () {
      const active = $('.history__slide.active');
      active.prev().addClass('active');
      slidesHistory.each(function () {
        $(this).not(active.prev()).removeClass('active');
      });
    });

    $('.history__slider-btn--next').on('click', function () {
      const active = $('.history__slide.active');
      active.next().addClass('active');
      slidesHistory.each(function () {
        $(this).not(active.next()).removeClass('active');
      });
    });

    swiperMassHistory.on('slideChange', function () {
      slidesHistory.each(function () {
        $(this).removeClass('active');
      });
      const previousSlideIndex = swiperMassHistory.previousIndex;
      const currentSlideIndex = swiperMassHistory.activeIndex;

      if (currentSlideIndex > previousSlideIndex) {
        $('.history__slide.swiper-slide-next').addClass('active');
      } else if (currentSlideIndex < previousSlideIndex) {
        $('.history__slide.swiper-slide-prev').addClass('active');
      }
    });
  } else {
    $('.history__year').each(function (index) {
      $(this).on('mouseenter', () => {
        slidesHistory.each(function () {
          $(this).removeClass('active');
        });
        slidesHistory.eq(index).addClass('active');
        swiperMassHistory.slideTo(index - 1);
      });
    });
  }

  const swiperMassMedia = new Swiper('.mass-media__slider', {
    speed: 800,
    breakpoints: {
      769: {
        slidesPerGroup: 4,
        slidesPerView: 4,
        spaceBetween: '1.8%',
      },
      100: {
        slidesPerGroup: 1,
        slidesPerView: 'auto',
        spaceBetween: '5%',
      },
    },
    navigation: {
      nextEl: '.mass-media__slider-btn--next',
      prevEl: '.mass-media__slider-btn--prev',
    },
    a11y: {
      prevSlideMessage: 'Назад',
      nextSlideMessage: 'Вперёд',
    },
  });

  const swiperVacancies = new Swiper('.vacancies__slider', {
    speed: 800,
    breakpoints: {
      769: {
        slidesPerGroup: 4,
        slidesPerView: 4,
        spaceBetween: '1.8%',
      },
      100: {
        slidesPerGroup: 1,
        slidesPerView: 'auto',
        spaceBetween: '4.5%',
      },
    },
    navigation: {
      nextEl: '.vacancies__slider-btn--next',
      prevEl: '.vacancies__slider-btn--prev',
    },
    a11y: {
      prevSlideMessage: 'Назад',
      nextSlideMessage: 'Вперёд',
    },
  });
}
